import React, { useState, useEffect } from "react";
import { UploadOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  Button,
  Card,
  Spin,
  DatePicker,
  Upload,
  Divider,
  Descriptions,
  Modal,
  Checkbox,
  message,
  Radio,
  Tag,
  Form,
  Tooltip,
} from "antd";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { RowState } from "../../model/common/RowState";
import { monthNames } from "../../model/common/CalendarType";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import * as UserType from "../../../redux/actions/actionTypes";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { regionLocale } from "../../common/InputNumberFormatter";
import { getMonthEndClose } from "../../../api/leaveApplicationApi";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManageLeaveApplicationForm = ({
  isLoading,
  setIsLoading,
  leaveApplication,
  fileList,
  setFileList,
  leaveTypesSelectionData,
  holidayProfilesData,
  offDaysData,
  appliedLeaveDays,
  empysData,
  leaveConfgData,
  loadPageData,
  onEmployeeChange,
  onSubmitted,
  onDeleted,
  onCancelled,
  onDataChanged,
  leaveTypeSelection,
  onLTDataChanged,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  onUploadAttachment,
  isOwner,
  isViewOnly = false,
  form,
  onRemoveAttachment,
  setIsDirty,
}) => {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  //const [showDrawer, setShowDrawer] = useState(false);
  const isSystemRcd = leaveApplication.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(leaveApplication.Status);
  const isSubmitted = isStatusSubmitted(leaveApplication.Status);
  const isVoid = isStatusVoid(leaveApplication.Status);
  const isApproved =
    isStatusPartialApproved(leaveApplication.Status) ||
    isStatusApproved(leaveApplication.Status);
  const isRequestCancelRejected =
    isApproved &&
    leaveApplication.RejectRequestCancelBy > 0 &&
    leaveApplication.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen &&
    leaveApplication.Reason.length > 0 &&
    (leaveApplication.RejectedBy > 0 || leaveApplication.ValidatedBy > 0);

  const isShowReason =
    isStatusVoid(leaveApplication.Status) ||
    isStatusRejected(leaveApplication.Status) ||
    isStatusRequestCancel(leaveApplication.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [isOpenRangePicker, setIsOpenRangePicker] = useState(false);
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isAdmin = !auth
    ? false
    : auth.UserRoleType === UserType.USER_ROLE_ADMIN;
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { LeaveTransKey } = useParams();

  useEffect(() => {
    loadPageData(LeaveTransKey, form.resetFields);
    // eslint-disable-next-line
  }, [LeaveTransKey]);

  useEffect(() => {
    form.setFieldsValue({
      LeaveApplicationDate: leaveApplication.LeaveApplicationDate,
    });
    // eslint-disable-next-line
  }, [leaveApplication.LeaveApplicationDate]);

  const handleSubmit = () => {
    handleSubmitFunc(0, leaveApplication.Reason);
  };

  function handleSubmitFunc(statusAction, cancelReason = "") {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        let tempValue = Object.assign({}, values);
        tempValue.FromLeaveApplicationDate =
          values.LeaveApplicationDate[0].startOf("day").$d;
        tempValue.ToLeaveApplicationDate =
          values.LeaveApplicationDate[1].startOf("day").$d;
        tempValue.Status = statusAction;
        tempValue.LTKey = values.LeaveType.value;
        tempValue.Reason = cancelReason;
        tempValue.UserKey = values.UserKey.value;

        let doubleAppliedDaysError = "";
        let doubleAppliedDaysDiffTypeError = "";

        for (
          var d = new Date(values.LeaveApplicationDate[0].startOf("day").$d);
          d <= tempValue.ToLeaveApplicationDate;
          d.setDate(d.getDate() + 1)
        ) {
          var apDays = appliedLeaveDays.filter(
            (x) => x.LeaveTransKey !== leaveApplication.LeaveTransKey
          );

          for (let i = 0; i < apDays.length; i++) {
            let formatDateHValue = dayjs(d);
            if (
              formatDateHValue.isBetween(
                apDays[i].FromLeaveApplicationDate,
                apDays[i].ToLeaveApplicationDate,
                undefined,
                "[]"
              ) &&
              (apDays[i].LeaveRule === tempValue.LeaveRule ||
                (apDays[i].LeaveRule !== tempValue.LeaveRule &&
                  apDays[i].LeaveRule === 0) ||
                tempValue.LeaveRule === 0)
            ) {
              if (apDays[i].LTKey === tempValue.LTKey) {
                doubleAppliedDaysError =
                  doubleAppliedDaysError +
                  formatDateHValue.format("DD/MM/YYYY") +
                  " , ";
              } else {
                doubleAppliedDaysDiffTypeError =
                  doubleAppliedDaysDiffTypeError +
                  formatDateHValue.format("DD/MM/YYYY") +
                  " , ";
              }
            }
          }
        }

        if (
          leaveApplication.DocTotalDay +
            leaveTypeSelection.TotalAvailableLeave <
          leaveApplication.TotalDay
        ) {
          toast.error(t("leaveApplicationForm.insufficient_leave_balance"));
          setIsLoading(false);
        } else if (doubleAppliedDaysError !== "") {
          toast.error(
            t("leaveApplicationForm.leave_already_applied_message") +
              doubleAppliedDaysError.substring(
                0,
                doubleAppliedDaysError.length - 2
              )
          );
          setIsLoading(false);
        } else if (
          leaveTypeSelection.TotalUsedCFDay -
            leaveApplication.DocTotalCFDay +
            leaveApplication.TotalCFDay >
          leaveTypeSelection.CarryForward
        ) {
          toast.error(
            t("leaveApplicationForm.insufficient_carry_forward_leave_balance")
          );
          setIsLoading(false);
        } else if (
          tempValue.FromLeaveApplicationDate.getMonth() !==
            tempValue.ToLeaveApplicationDate.getMonth() &&
          leaveTypeSelection.LTType === 2
        ) {
          toast.error(t("leaveApplicationForm.unable_to_cross_unpaid_leave"));
          setIsLoading(false);
        } else if (
          tempValue.FromLeaveApplicationDate.getMonth() + 1 <=
            leaveTypeSelection.CFExpiryMth &&
          tempValue.ToLeaveApplicationDate.getMonth() + 1 >
            leaveTypeSelection.CFExpiryMth
        ) {
          toast.error(
            t("leaveApplicationForm.unable_to_cross_month_carry_forward")
          );
          setIsLoading(false);
        } else if (leaveTypeSelection.LTType === 2) {
          getMonthEndClose(
            tempValue.UserKey,
            tempValue.FromLeaveApplicationDate.getFullYear(),
            tempValue.FromLeaveApplicationDate.getMonth() + 1
          )
            .then((result) => {
              if (result) {
                toast.error(
                  t(
                    "leaveApplicationForm.not_allowed_apply_closed_payroll_month_message"
                  )
                );
                setIsLoading(false);
              } else {
                const tempData = leaveTypesSelectionData.filter(
                  (item) => item.Code === "AL"
                );
                if (tempData.length > 0) {
                  if (
                    tempData[0].TotalLeave >
                    tempData[0].UsedLeave +
                      (dayjs().month() + 1 > tempData[0].CFExpiryMth
                        ? tempData[0].ToBeExpiredCarryForward
                        : 0)
                  ) {
                    toast.error(
                      t(
                        "leaveApplicationForm.annual_leave_must_be_fully_utilize_first"
                      )
                    );
                    setIsLoading(false);
                  } else {
                    if (doubleAppliedDaysDiffTypeError !== "") {
                      toast.warn(
                        t(
                          "leaveApplicationForm.different_leave_type_already_applied_message"
                        ) +
                          doubleAppliedDaysDiffTypeError.substring(
                            0,
                            doubleAppliedDaysDiffTypeError.length - 2
                          )
                      );
                    }
                    uploadAttachments()
                      .then((result) => {
                        onSubmitted(tempValue, result);
                      })
                      .catch(() => {
                        toast.error(t("general.fail_to_upload_file"));
                      });
                  }
                }
              }
            })
            .catch((error) => {
              setIsLoading(false);
              showErrorMessage(
                t(
                  "leaveApplicationForm.fail_to_check_payroll_month_end_status"
                ),
                error
              );
            });
        } else {
          if (doubleAppliedDaysDiffTypeError !== "") {
            toast.warn(
              t(
                "leaveApplicationForm.different_leave_type_already_applied_message"
              ) +
                doubleAppliedDaysDiffTypeError.substring(
                  0,
                  doubleAppliedDaysDiffTypeError.length - 2
                )
            );
          }

          uploadAttachments()
            .then((result) => {
              onSubmitted(tempValue, result);
            })
            .catch(() => {
              toast.error(t("general.fail_to_upload_file"));
            });
        }
      })
      .catch(() => {});
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    handleSubmitFunc(2, reason);
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        leaveApplication.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        let newData = leaveApplication.Attaches.map((x) => {
          return x.uid === file.uid && x.RowState !== RowState.DELETED
            ? { ...x, RowState: RowState.DELETED }
            : x;
        });
        onDataChanged({ ...leaveApplication, Attaches: newData });

        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: isOpen && isOwner,
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  // function handleShowDrawer() {
  //   setShowDrawer(true);
  // }

  // function handleHideDrawer() {
  //   setShowDrawer(false);
  // }

  function dateInArray(queryDate, tempHoliday) {
    return Boolean(
      tempHoliday.filter(function (date) {
        return date.isSame(queryDate);
      }).length
    );
  }

  function onChangeDate(date, custom) {
    if (date === null || date === undefined) return;
    if (
      (leaveTypeSelection.Code === "MAL" ||
        leaveTypeSelection.Code === "PAL") &&
      custom !== "calendar"
    )
      return;
    if (date.length > 0) {
      let numPH = 0;
      let numNextYearLeave = 0;
      let diffDay = date[1].diff(date[0], "day");
      let fromFormatDate = date[0].startOf("day");
      let toFormatDate = date[1].startOf("day");
      let startNextYearDate = dayjs([
        leaveApplication.TransDate.getFullYear() + 1,
        1,
        1,
      ]);
      let tempHoliday = [];

      let diffCFDay =
        toFormatDate.year() === leaveApplication.TransDate.getFullYear()
          ? -1
          : fromFormatDate.year() === leaveApplication.TransDate.getFullYear()
          ? date[1].diff(startNextYearDate, "day")
          : date[1].diff(date[0], "day");

      if (leaveTypeSelection.IsIncludeHoliday !== true) {
        if (holidayProfilesData.length > 0) {
          let x = 0;
          for (x = 0; x < holidayProfilesData.length; x++) {
            let formatDateHValue =
              holidayProfilesData[x].MomentHolidayDate.startOf("day");

            if (
              formatDateHValue.isBetween(
                fromFormatDate,
                toFormatDate,
                "days",
                "[]"
              ) &&
              !dateInArray(formatDateHValue, tempHoliday)
            ) {
              tempHoliday.push(formatDateHValue);
              numPH += 1;
              if (
                formatDateHValue.year() ===
                leaveApplication.TransDate.getFullYear() + 1
              ) {
                numNextYearLeave += 1;
              }
            }
          }
        }

        if (offDaysData.length > 0) {
          let x = 0;
          let day = 0;
          for (x = 0; x < offDaysData.length; x++) {
            var current = fromFormatDate.clone();
            if (offDaysData[x].Mon) {
              current = fromFormatDate.clone();
              day = 1;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Tue) {
              current = fromFormatDate.clone();
              day = 2;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Wed) {
              current = fromFormatDate.clone();
              day = 3;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Thu) {
              current = fromFormatDate.clone();
              day = 4;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Fri) {
              current = fromFormatDate.clone();
              day = 5;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Sat) {
              current = fromFormatDate.clone();
              day = 6;
              if (
                current.day(day).isAfter(fromFormatDate) &&
                current.day(day).isBefore(toFormatDate)
              ) {
                if (!dateInArray(current.day(day), tempHoliday)) {
                  if (
                    current.day(day).year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.day(day).clone());
                }
              }

              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
            if (offDaysData[x].Sun) {
              current = fromFormatDate.clone();
              day = 0;
              while (current.day(7 + day).isBefore(toFormatDate)) {
                current = current.day(7 + day);
                if (!dateInArray(current, tempHoliday)) {
                  if (
                    current.year() ===
                    leaveApplication.TransDate.getFullYear() + 1
                  ) {
                    numNextYearLeave += 1;
                  }
                  numPH += 1;
                  tempHoliday.push(current.clone());
                }
              }
            }
          }
        }
      }

      let defaultAdd = 1;

      if (leaveConfgData.AllowHalfDay && diffDay === 0) {
        if (leaveApplication.LeaveRule !== 0) {
          defaultAdd = 0.5;
        }
      }
      let tempCalDay = {
        TotalDay: diffDay + defaultAdd - numPH,
        TotalCFDay: diffCFDay + 1 - numNextYearLeave,
        LeaveApplicationDate: date,
      };

      if (tempCalDay.TotalDay > 1) {
        tempCalDay.LeaveRule = 0;
        form.resetFields(["LeaveRule"]);
      }

      onDataChanged({ ...leaveApplication, ...tempCalDay });
    }
  }

  function onCalendarChangeDate(date, _, info) {
    if (date === null || date === undefined) return;
    if (date.length > 0) {
      if (leaveTypeSelection.TotalAvailableLeave !== undefined) {
        if (leaveTypeSelection.Code === "MAL") {
          form.resetFields(["LeaveApplicationDate"]);
          let tempDateFrom, tempDateTo;
          let editStart = date[0] !== null && info.range === "start";
          let editEnd = date[1] !== null && info.range === "end";

          if (editStart) {
            tempDateTo = date[0].clone();
          } else if (editEnd) {
            tempDateFrom = date[1].clone();
          }

          let maternityRule = form.getFieldValue("LeaveMaternityRule");
          let availableLeave =
            leaveApplication.DocTotalDay +
            leaveTypeSelection.TotalAvailableLeave;

          if (maternityRule === 0) {
            if (availableLeave > 59 || availableLeave === 0) {
              if (editStart) {
                tempDateTo = tempDateTo.add(59, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(59, "d");
              }
            } else {
              if (editStart) {
                tempDateTo = tempDateTo.add(availableLeave - 1, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(availableLeave - 1, "d");
              }
            }
          } else {
            if (
              availableLeave > leaveTypeSelection.MALOptions - 1 ||
              availableLeave === 0
            ) {
              if (editStart) {
                tempDateTo = tempDateTo.add(
                  leaveTypeSelection.MALOptions - 1,
                  "d"
                );
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(
                  leaveTypeSelection.MALOptions - 1,
                  "d"
                );
              }
            } else {
              if (editStart) {
                tempDateTo = tempDateTo.add(availableLeave - 1, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(availableLeave - 1, "d");
              }
            }
          }

          if (editStart) {
            date = [date[0], tempDateTo];
          } else if (editEnd) {
            date = [tempDateFrom, date[1]];
          }

          form.setFieldsValue({
            LeaveApplicationDate: date,
          });
          onChangeDate(date, "calendar");
          setIsOpenRangePicker(false);
        } else if (leaveTypeSelection.Code === "PAL") {
          form.resetFields(["LeaveApplicationDate"]);
          let tempDateFrom, tempDateTo;
          let editStart = date[0] !== null && info.range === "start";
          let editEnd = date[1] !== null && info.range === "end";

          if (editStart) {
            tempDateTo = date[0].clone();
          } else if (editEnd) {
            tempDateFrom = date[1].clone();
          }

          let paternityRule = form.getFieldValue("LeavePaternityRule");
          let availableLeave =
            leaveApplication.DocTotalDay +
            leaveTypeSelection.TotalAvailableLeave;

          if (paternityRule === 0) {
            if (availableLeave > 6 || availableLeave === 0) {
              if (editStart) {
                tempDateTo = tempDateTo.add(6, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(6, "d");
              }
            } else {
              if (editStart) {
                tempDateTo = tempDateTo.add(availableLeave - 1, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(availableLeave - 1, "d");
              }
            }
          } else {
            if (
              availableLeave > leaveTypeSelection.MALOptions - 1 ||
              availableLeave === 0
            ) {
              if (editStart) {
                tempDateTo = tempDateTo.add(
                  leaveTypeSelection.MALOptions - 1,
                  "d"
                );
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(
                  leaveTypeSelection.MALOptions - 1,
                  "d"
                );
              }
            } else {
              if (editStart) {
                tempDateTo = tempDateTo.add(availableLeave - 1, "d");
              } else if (editEnd) {
                tempDateFrom = tempDateFrom.subtract(availableLeave - 1, "d");
              }
            }
          }

          if (editStart) {
            date = [date[0], tempDateTo];
          } else if (editEnd) {
            date = [tempDateFrom, date[1]];
          }

          form.setFieldsValue({
            LeaveApplicationDate: date,
          });
          onChangeDate(date, "calendar");
          setIsOpenRangePicker(false);
        }
      }
    }
  }

  function handleOpenChange(open) {
    setIsOpenRangePicker(open);
  }

  function formatLocaleDay(day) {
    if (day === "Isn" || day === "Sen") {
      day = "Mon";
    } else if (day === "Sel") {
      day = "Tue";
    } else if (day === "Rab") {
      day = "Wed";
    } else if (day === "Kha" || day === "Kam") {
      day = "Thu";
    } else if (day === "Jum") {
      day = "Fri";
    } else if (day === "Sab") {
      day = "Sat";
    } else if (day === "Ahd" || day === "Min") {
      day = "Sun";
    }

    return day;
  }

  // function getListData(value, hvalue, ovalue) {
  //   let listData = [];

  //   if (hvalue.length > 0) {
  //     let x = 0;
  //     let formatDate = value.startOf("day");
  //     for (x = 0; x < hvalue.length; x++) {
  //       let formatDateHValue = hvalue[x].MomentHolidayDate.startOf("day");
  //       if (formatDate.isSame(formatDateHValue)) {
  //         listData.push({ type: "success", content: hvalue[x].HolidayDesc });
  //       }
  //     }
  //   }

  //   if (ovalue.length > 0) {
  //     let x = 0;
  //     let formatDay = formatLocaleDay(value.format("ddd"));
  //     for (x = 0; x < ovalue.length; x++) {
  //       if (ovalue[x][formatDay]) {
  //         listData.push({ type: "default", content: "Rest Day" });
  //       }
  //     }
  //   }

  //   return listData || [];
  // }

  // function dateCellRender(value) {
  //   const listData = getListData(value, holidayProfilesData, offDaysData);

  //   return (
  //     <div>
  //       {listData.map((item) => (
  //         <li key={item.content}>
  //           <Badge status={item.type} text={item.content} />
  //         </li>
  //       ))}
  //     </div>
  //   );
  // }

  // function getMonthData(value, hvalue) {
  //   let numPH = 0;
  //   if (hvalue.length > 0) {
  //     let x = 0;
  //     let month = value.month() + 1;
  //     let year = value.year();
  //     for (x = 0; x < hvalue.length; x++) {
  //       let monthHValue = hvalue[x].MomentHolidayDate.month() + 1;
  //       let yearHValue = hvalue[x].MomentHolidayDate.year();
  //       if (month === monthHValue && year === yearHValue) {
  //         numPH += 1;
  //       }
  //     }
  //   }
  //   return numPH;
  // }

  // function monthCellRender(value) {
  //   const num = getMonthData(value, holidayProfilesData);
  //   return num ? (
  //     <div>
  //       <section>{num}</section>
  //       <span>Public Holiday</span>
  //     </div>
  //   ) : null;
  // }

  function disabledDate(value) {
    if (leaveTypeSelection.IsIncludeHoliday !== true) {
      if (holidayProfilesData.length > 0) {
        let x = 0;
        let formatDate = value.startOf("day");
        for (x = 0; x < holidayProfilesData.length; x++) {
          let formatDateHValue =
            holidayProfilesData[x].MomentHolidayDate.startOf("day");
          if (formatDate.isSame(formatDateHValue)) {
            return value;
          }
        }
      }

      if (offDaysData.length > 0) {
        let x = 0;
        let formatDay = formatLocaleDay(value.format("ddd"));
        for (x = 0; x < offDaysData.length; x++) {
          if (offDaysData[x][formatDay]) {
            return value;
          }
        }
      }
    }

    if (
      value.year() < leaveApplication.TransDate.getFullYear() ||
      value.year() > leaveApplication.TransDate.getFullYear() + 1
    ) {
      return value;
    }
  }

  function disabledYear(value) {
    if (value.year() > dayjs().year()) {
      return value;
    }
  }

  function onChangeLeaveTypeSelection(value) {
    const tempData = leaveTypesSelectionData.filter(
      (item) => item.Key === value.value
    );
    onLTDataChanged(tempData[0]);
    form.resetFields(["LeaveApplicationDate", "LeaveRule"]);

    form.setFieldsValue({
      LeaveMaternityRule: 0,
      LeavePaternityRule: 0,
    });

    let tempCalDay = {
      TotalDay: 0,
      TotalCFDay: 0,
      LeaveRule: 0,
      LeaveApplicationDate: "",
    };

    onDataChanged({ ...leaveApplication, ...tempCalDay });
  }

  function onChangeEmployee(value) {
    onLTDataChanged({});
    form.resetFields(["LeaveType", "LeaveApplicationDate", "LeaveRule"]);

    form.setFieldsValue({
      LeaveMaternityRule: 0,
      LeavePaternityRule: 0,
    });

    let tempCalDay = {
      LTKey: -1,
      TotalDay: 0,
      TotalCFDay: 0,
      LeaveRule: 0,
      LeaveApplicationDate: "",
    };

    onDataChanged({ ...leaveApplication, ...tempCalDay });

    const tempData = empysData.filter((item) => item.UserKey === value.value);
    if (tempData.length > 0) {
      onEmployeeChange(
        tempData[0].UserKey,
        tempData[0].EmpyKey,
        -1,
        leaveApplication.TransDate.getFullYear()
      );
    }
  }

  function onChangeLeaveRule(e) {
    let tempCalDay = {};

    if (e.target.value === 0) {
      tempCalDay.TotalDay = 1;
      if (leaveApplication.TotalCFDay > 0) {
        tempCalDay.TotalCFDay = 1;
      }
    } else {
      tempCalDay.TotalDay = 0.5;
      if (leaveApplication.TotalCFDay > 0) {
        tempCalDay.TotalCFDay = 0.5;
      }
    }

    onDataChanged({ ...leaveApplication, ...tempCalDay });
  }

  function onChangeMaternityRule(e) {
    let date = form.getFieldValue("LeaveApplicationDate");
    if (date.length > 0) {
      let tempDate = date[0].clone();

      let availableLeave =
        leaveApplication.DocTotalDay + leaveTypeSelection.TotalAvailableLeave;

      if (e.target.value === 0) {
        if (availableLeave > 59 || availableLeave === 0) {
          tempDate = tempDate.add(59, "d");
        } else {
          tempDate = tempDate.add(availableLeave - 1, "d");
        }
      } else {
        if (
          availableLeave > leaveTypeSelection.MALOptions - 1 ||
          availableLeave === 0
        ) {
          tempDate = tempDate.add(leaveTypeSelection.MALOptions - 1, "d");
        } else {
          tempDate = tempDate.add(availableLeave - 1, "d");
        }
      }

      date = [date[0], tempDate];

      form.setFieldsValue({
        LeaveApplicationDate: date,
      });
      onChangeDate(date, "calendar");
    }
  }

  function onChangePaternityRule(e) {
    let date = form.getFieldValue("LeaveApplicationDate");
    if (date.length > 0) {
      let tempDate = date[0].clone();

      let availableLeave =
        leaveApplication.DocTotalDay + leaveTypeSelection.TotalAvailableLeave;

      if (e.target.value === 0) {
        if (availableLeave > 6 || availableLeave === 0) {
          tempDate = tempDate.add(6, "d");
        } else {
          tempDate = tempDate.add(availableLeave - 1, "d");
        }
      } else {
        if (
          availableLeave > leaveTypeSelection.MALOptions - 1 ||
          availableLeave === 0
        ) {
          tempDate = tempDate.add(leaveTypeSelection.MALOptions - 1, "d");
        } else {
          tempDate = tempDate.add(availableLeave - 1, "d");
        }
      }

      date = [date[0], tempDate];

      form.setFieldsValue({
        LeaveApplicationDate: date,
      });
      onChangeDate(date, "calender");
    }
  }

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("leaveApplicationForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk() {
        onDeleted();
      },
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("leaveApplicationForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk() {
        handleSubmitFunc(0);
      },
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form.validateFields().then(() => {
      confirm({
        title: t("general.submit_confirmation"),
        content: t("leaveApplicationForm.submit_message"),
        okText: t("general.yes"),
        cancelText: t("general.no"),
        onOk() {
          handleSubmitFunc(1);
        },
        onCancel() {},
      });
    });
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function handleYearChange(date) {
    onLTDataChanged({});
    form.resetFields(["LeaveType", "LeaveApplicationDate", "LeaveRule"]);

    form.setFieldsValue({
      LeaveMaternityRule: 0,
      LeavePaternityRule: 0,
    });

    let tempCalDay = {
      LTKey: -1,
      TotalDay: 0,
      TotalCFDay: 0,
      LeaveRule: 0,
      LeaveApplicationDate: "",
      TransDate: new Date(),
    };

    if (date.$y !== dayjs().year()) {
      var d = new Date(date.$y, 11, 31);
      tempCalDay.TransDate = d;
    }

    onDataChanged({ ...leaveApplication, ...tempCalDay });

    let userKey = form.getFieldValue("UserKey");
    const tempData = empysData.filter(
      (item) => item.UserKey === parseInt(userKey.value)
    );
    if (tempData.length > 0) {
      onEmployeeChange(tempData[0].UserKey, tempData[0].EmpyKey, -1, date.$y);
    }
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("leaveApplicationWidget.leave_application")}
                </span>{" "}
                <Tag
                  color="green"
                  style={{
                    marginLeft: 10,
                    display:
                      leaveApplication.IsRequireValidation &&
                      leaveApplication.IsValidated
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.validated")}
                </Tag>
              </div>
            }
            extra={
              // <Button
              //   onClick={handleShowDrawer}
              //   shape="circle"
              //   icon={<CalendarOutlined />}
              // />
              <DatePicker
                allowClear={false}
                placeholder={t("general.year")}
                picker="year"
                suffixIcon={[]}
                style={{ width: 60 }}
                defaultValue={dayjs(
                  dayjs().year(leaveApplication.TransDate.getFullYear()),
                  "YYYY"
                )}
                value={dayjs(
                  dayjs().year(leaveApplication.TransDate.getFullYear()),
                  "YYYY"
                )}
                disabled={!isOpen || !isOwner || isSystemRcd}
                disabledDate={disabledYear}
                onChange={handleYearChange}
              />
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                {/* <Drawer
                  title="Calendar"
                  placement="right"
                  onClose={handleHideDrawer}
                  open={showDrawer}
                  width="80%"
                >
                  <Calendar
                    dateCellRender={dateCellRender}
                    monthCellRender={monthCellRender}
                  />
                </Drawer> */}

                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label={t("general.employee_name")}
                      name="UserKey"
                      rules={[
                        {
                          required: true,
                          message: t("general.employee_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !empysData.some(
                                (x) => x.UserKey === parseInt(value.value)
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  t("general.employee_required_validation")
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        leaveApplication.LeaveTransKey === -1
                          ? {
                              key: !auth ? -1 : auth.LocalUserKey,
                              value: !auth ? -1 : auth.LocalUserKey,
                              label: !auth ? "" : auth.UserName,
                            }
                          : {
                              key: leaveApplication.UserKey,
                              value: leaveApplication.UserKey,
                              label: leaveApplication.EmpyName,
                            }
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={empysData}
                        valueProp={"UserKey"}
                        textProp={"EmpyName"}
                        disabledProp={"Active"}
                        placeholder={t("general.employee_placeholder")}
                        disabled={
                          !isOpen || !isOwner || isSystemRcd || !isAdmin
                        }
                        onChange={onChangeEmployee}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={t("general.leave_type")}
                      name="LeaveType"
                      rules={[
                        {
                          required: true,
                          message: t("general.leave_type_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !leaveTypesSelectionData.some(
                                (x) => x.Key === value.value
                              )
                            ) {
                              return Promise.reject(
                                new Error(t("general.invalid_leave_type"))
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        leaveApplication.LTKey === -1
                          ? undefined
                          : {
                              key: leaveApplication.LTKey,
                              value: leaveApplication.LTKey,
                              label:
                                leaveApplication.LTCode +
                                " - " +
                                leaveApplication.LTDesc,
                            }
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={leaveTypesSelectionData}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("general.leave_type_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        onChange={onChangeLeaveTypeSelection}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t("general.status")}
                      name="StatusDesc"
                      initialValue={Status[leaveApplication.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      display: isShowReason ? "" : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        isRequestCancelRejected
                          ? t("general.reason_of_cancel_request_rejected")
                          : t("general.reason")
                      }
                      name="Reason"
                      initialValue={leaveApplication.Reason}
                    >
                      <TextArea
                        disabled={true}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={8}>
                    <Form.Item
                      label={t("general.date")}
                      name="LeaveApplicationDate"
                      rules={[
                        {
                          required: true,
                          message: t("general.date_required_validation"),
                        },
                      ]}
                      initialValue={leaveApplication.LeaveApplicationDate}
                    >
                      <RangePicker
                        format="DD/MM/YYYY"
                        showToday={false}
                        disabledDate={disabledDate}
                        onChange={onChangeDate}
                        onCalendarChange={onCalendarChangeDate}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        placeholder={[
                          t("general.start_date"),
                          t("general.end_date"),
                        ]}
                        onOpenChange={handleOpenChange}
                        open={isOpenRangePicker}
                        value={leaveApplication.LeaveApplicationDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={16}
                    style={{
                      paddingTop: 22,
                      display:
                        (leaveApplication.TotalDay === 0.5 ||
                          leaveApplication.TotalDay === 1) &&
                        leaveConfgData.AllowHalfDay
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label=""
                      name="LeaveRule"
                      initialValue={leaveApplication.LeaveRule}
                    >
                      <Radio.Group
                        onChange={onChangeLeaveRule}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        <Radio.Button value={0}>
                          {t("leaveApplicationForm.full_day")}
                        </Radio.Button>
                        <Radio.Button value={1}>AM</Radio.Button>
                        <Radio.Button value={2}>PM</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={16}
                    style={{
                      paddingTop: 22,
                      display:
                        leaveTypeSelection.Code === "MAL" &&
                        leaveTypeSelection.MALOptions !== 60
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label=""
                      name={"LeaveMaternityRule"}
                      initialValue={
                        leaveApplication.TotalDay === 60 ||
                        leaveApplication.TotalDay === 0
                          ? 0
                          : 1
                      }
                    >
                      <Radio.Group
                        onChange={onChangeMaternityRule}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        <Radio.Button value={0}>
                          60 {t("leaveApplicationForm.days_capital")}
                        </Radio.Button>
                        <Radio.Button value={1}>
                          {leaveTypeSelection.MALOptions}{" "}
                          {t("leaveApplicationForm.days_capital")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={16}
                    style={{
                      paddingTop: 22,
                      display:
                        leaveTypeSelection.Code === "PAL" &&
                        leaveTypeSelection.MALOptions !== 7
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label=""
                      name="LeavePaternityRule"
                      initialValue={
                        leaveApplication.TotalDay === 7 ||
                        leaveApplication.TotalDay === 0
                          ? 0
                          : 1
                      }
                    >
                      <Radio.Group
                        onChange={onChangePaternityRule}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        <Radio.Button value={0}>
                          7 {t("leaveApplicationForm.days_capital")}
                        </Radio.Button>
                        <Radio.Button value={1}>
                          {leaveTypeSelection.MALOptions}{" "}
                          {t("leaveApplicationForm.days_capital")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("general.remarks")}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.remarks_required_validation"),
                        },
                        {
                          max: 500,
                          message: t(
                            "general.remarks_max_length_500_validation"
                          ),
                        },
                      ]}
                      initialValue={leaveApplication.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={t("general.attachment")}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload
                        {...uploadProp()}
                        listType="picture"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        <Button
                          disabled={
                            !isOpen || !isOwner || isViewOnly || isSystemRcd
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item>
                      <Descriptions bordered size="small">
                        <Descriptions.Item
                          label={t("leaveApplicationForm.no_of_apply_days")}
                        >
                          {leaveApplication.TotalDay.toLocaleString(
                            regionLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                            }
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("general.date_applied")}>
                          {leaveApplication.TransDate.toLocaleDateString(
                            "en-GB"
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item>
                      <Descriptions
                        bordered
                        size="small"
                        style={{
                          display:
                            leaveTypeSelection &&
                            Object.keys(leaveTypeSelection).length !== 0 &&
                            isOwner
                              ? "block"
                              : "none",
                        }}
                      >
                        <Descriptions.Item
                          label={t("leaveApplicationForm.used_leave_info")}
                        >
                          {leaveTypeSelection.UsedLeave !== undefined
                            ? leaveTypeSelection.UsedLeave.toLocaleString(
                                regionLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : ""}{" "}
                          /{" "}
                          {leaveTypeSelection.TotalLeave !== undefined
                            ? leaveTypeSelection.TotalLeave.toLocaleString(
                                regionLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : ""}
                          {leaveTypeSelection.CarryForward > 0 ? (
                            leaveTypeSelection.ExpiredCarryForward > 0 ? (
                              <Tooltip
                                title={
                                  leaveTypeSelection.ExpiredCarryForward.toLocaleString(
                                    regionLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                    }
                                  ) +
                                  t(
                                    "leaveApplicationForm.days_of_brought_forward_are_expired"
                                  )
                                }
                              >
                                <InfoCircleTwoTone style={{ marginLeft: 10 }} />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  leaveTypeSelection.ToBeExpiredCarryForward.toLocaleString(
                                    regionLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                    }
                                  ) +
                                  t(
                                    "leaveApplicationForm.days_of_brought_forward_will_be_expire_after"
                                  ) +
                                  monthNames[leaveTypeSelection.CFExpiryMth]
                                }
                              >
                                <InfoCircleTwoTone style={{ marginLeft: 10 }} />
                              </Tooltip>
                            )
                          ) : (
                            ""
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("leaveApplicationForm.available_leave")}
                        >
                          {leaveTypeSelection.TotalAvailableLeave !== undefined
                            ? leaveTypeSelection.TotalAvailableLeave.toLocaleString(
                                regionLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : ""}
                        </Descriptions.Item>
                      </Descriptions>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit()}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen &&
                              leaveApplication.LeaveTransKey !== -1 &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isSystemRcd &&
                              !isViewOnly
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={leaveApplication}
            showFooter={leaveApplication.LeaveTransKey !== -1 ? true : false}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              leaveApplication.Status === 4 || leaveApplication.Status === 1
                ? true
                : false
            }
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>
      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Leave"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageLeaveApplicationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  leaveApplication: PropTypes.object.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  leaveTypesSelectionData: PropTypes.array.isRequired,
  holidayProfilesData: PropTypes.array.isRequired,
  leaveConfgData: PropTypes.object.isRequired,
  offDaysData: PropTypes.array.isRequired,
  appliedLeaveDays: PropTypes.array.isRequired,
  empysData: PropTypes.array.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onEmployeeChange: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  leaveTypeSelection: PropTypes.object.isRequired,
  onLTDataChanged: PropTypes.func.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageLeaveApplicationForm;
